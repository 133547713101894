import React from "react";

import styles from "./pageTitle.module.css";
import classNames from "classnames";

interface Props {
  title: string | JSX.Element;
  subtitle?: string;
  titleBelow?: boolean;
  noBorder?: boolean;
  topSpacing?: "small" | "standard";
}

const PageTitle = ({
  title,
  subtitle,
  titleBelow = false,
  noBorder = false,
  topSpacing = "standard",
}: Props): JSX.Element => {
  if (titleBelow) {
    return (
      <>
        <div className={styles.flexContainer}>
          <div className={styles.sectionContainer}>
            <div className={styles.paddingContainerBelow}>
              <div className={styles.titleContainerBelow}></div>
            </div>
            <div className={styles.titleBelow}>{title}</div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className={styles.paddingContainer}>
        <div
          className={classNames(styles.titleContainer, {
            [styles.noBorder]: noBorder,
            [styles.titleContainerSmallSpacing]: topSpacing === "small",
          })}
        >
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
      </div>
    );
  }
};

export default PageTitle;
