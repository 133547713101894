import React from "react";
import { SliceZone } from "@prismicio/react";
import * as prismicH from "@prismicio/helpers";

import { createClient, linkResolver } from "../prismic";
import * as Slices from "../slices";
import PageTitle from "../components/pageTitle/PageTitle";
import PageSection from "../components/section/PageSection";
import ProductDetail from "../components/productDetail/ProductDetail";
import { useShopContext } from "../context/ShopContext";
import { useRouter } from "next/router";

const Page = ({ page }) => {
  const data = page?.data;

  return (
    <>
      {data?.title[0]?.text && (
        <PageTitle title={data?.title[0].text} titleBelow />
      )}
      <SliceZone
        sliceProps={{
          pageData: data,
          globalData: page,
        }}
        {...data}
        components={Slices.components}
      />
    </>
  );
};

// Fetch content from prismic
export const getStaticProps = async ({ params, previewData }) => {
  const client = createClient();
  const previewRef = previewData ? previewData.ref : null;
  let refOption = previewRef ? { ref: previewRef } : {};

  let page = null;
  try {
    page = await client?.getByUID("page", params.uid, refOption);
  } catch (err) {} //! Stops unable to load static props error occuring - ask Siebe if this is the best way

  return {
    props: { page },
  };
};

export const getStaticPaths = async () => {
  const client = createClient();
  const documents = await client.getAllByType("page");

  return {
    paths: documents.map((doc) => prismicH.asLink(doc, linkResolver)),
    fallback: true,
  };
};

export default Page;
